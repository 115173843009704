import styled from "@emotion/styled";
import { theme } from "@product/scmp-sdk";

import { HeaderMenu } from "./header-menu";

export const StyledHeaderMenu = styled(HeaderMenu)`
  display: none;

  ${theme.breakpoints.up("desktop")} {
    display: flex;
  }
`;
